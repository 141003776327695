<template>
  <div class="tw-p-5">
    <div class="lg:tw-w-3/12 md:tw-w-5/12 tw-pt-20" style="margin: 0 auto">
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(resetPassword)">
        <div class="tw-mt-16">
          <h4 class="find">Reset Password</h4>
        </div>
          <div class="tw-mb-10 tw-mt-2">
            <small>Enter your email address to reset your password.</small>
          </div>
        <div class="tw-mt-4">
          <small>Email Address</small>
          <validation-provider
              v-slot="{ errors }"
              name="email"
              rules="required">
          <v-text-field
              id="email"
              hide-details
              label="Enter your email address"
              v-model="email"
              @input="value => { email = value.toLowerCase();}"
              solo/>
            <span class="err">{{ errors[0] }}</span>
          </validation-provider>
        </div>
        <br>
          <br>
        <div class="tw-mt-2">
          <BaseButton :loading="loading" :block="true" button-text="Send reset link to email"/>
        </div>
        <div>

        </div>
      </form>
      </validation-observer>
    </div>

  </div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
import {resetPassword} from "@/services/api/AuthApiService";

export default {
  name: "ResetPassword",
  components: {BaseButton},
  data(){
    return{
      value: false,
      email:'',
      loading:false
    }
  },

  methods:{
    resetPassword() {
      this.loading = true
      const data = {
        login: this.email
      };
      resetPassword(data).then(
          response => {
            console.log(response);
            this.$displaySnackbar({
              message: response.data.detail,
              success: true
            })
            this.email = '';
            this.$router.push('/login')
          }).catch(error=>{
            console.log(error.response.data.detail);
            this.$displaySnackbar({
              message: error.response.data.detail,
              success: false
            })
          }
      )
      .finally(()=>this.loading = false)

    }

  }
}
</script>

<style scoped>
.find {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #001343;
}


.or{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #475661;
}
.forget{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  color: #008EEF;
}
.err{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: red;

}
</style>
